<template>
  <div class="form-group row">
    <label class="col-6 col-form-label py-0">{{ label }}</label>
    <div class="col-6">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
  },
};
</script>
